import React from "react";
import { json, useNavigate } from "react-router-dom";

import Button from "../../components/Button";
import { useAppContext } from "../../utils/providers/AppProvider";
import { toast } from "react-toastify";

export const Login = () => {
  const { setUser, user } = useAppContext();
  const navigate = useNavigate();
  const handleLogin = () => {
    const username = document.getElementById("username").value;
    const password = document.getElementById("password").value;
    if (username == "admin" && password === "Nadir@2024") {
      toast.success("Logged In!");
      setUser({ isAdmin: true });
      sessionStorage.setItem("user", JSON.stringify({ isAdmin: true }));
      return navigate("/dashboard");
    }
    return toast.error("Incorrect Credentials !");
  };
  return (
    <div className={"py-10 px-10 container mx-auto justify-center flex "}>
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        className="flex flex-col gap-2 px-10 py-10 bg-primary rounded-lg  sm:w-1/3 w-full shadow-md drop-shadow-md shadow-black"
      >
        <p className="text-lg text-center">Please Login</p>
        <p>username</p>
        <input
          type="text"
          placeholder="username"
          className=" rounded-md p-2 border-none text-black"
          id={"username"}
        />
        <p>password</p>
        <input
          type="password"
          placeholder="********"
          className=" rounded-md p-2 border-none text-black"
          id={"password"}
        />
        <Button title="Login" onClick={handleLogin} />
      </div>
    </div>
  );
};
