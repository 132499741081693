import React, { useState } from "react";
import logo1 from "../assets/logo-img.svg";
import hamburger from "../assets/svg/hamburger.svg";
import logo2 from "../assets/logo-text.svg";
import { Link, NavLink } from "react-router-dom";
import Button from "./Button";
import ModalBackdrop from "../assets/ModalBackdrop";

const Navbar = () => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisible = () => setIsVisible(!isVisible);
  return (
    <header className="shadow-lg">
      <div className="flex items-center justify-between gap-4 h-[90px] px-4 lg:px-20 ">
        <div data-aos="zoom-in" className="flex items-center gap-2 lg:gap-4">
          <img
            className="w-[2rem] h-[2rem] lg:w-[3.5rem] lg:h-[3.5rem]"
            src={logo1}
            alt="logo"
          />
          <img
            src={logo2}
            alt="logo"
            className="h-[1rem] lg:w-auto lg:h-auto"
          />
        </div>

        <nav>
          <img
            src={hamburger}
            className="h-[0.75rem] sm:hidden"
            alt="hamburger"
            onClick={toggleVisible}
          />
          <NavLinks className={"hidden lg:flex gap-8 items-center"} />
          {isVisible && (
            <ModalBackdrop closeModal={() => setIsVisible(false)}>
              <NavLinks className={"flex flex-col gap-8 items-center"} />
            </ModalBackdrop>
          )}
        </nav>
      </div>
    </header>
  );
};

const NavLinks = ({ className }) => {
  return (
    <ul className={className}>
      {NAV_LINKS?.map((link, index) => (
        <li
          key={link.path}
          data-aos="fade-down"
          data-aos-delay={`${(index + 1) * 200}`}
        >
          <NavLink
            style={({ isActive }) => {
              return {
                color: isActive ? "#59aec2" : "#fff",
              };
            }}
            to={link.path}
            className="hover:text-secondary font-medium"
          >
            {link.title}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default Navbar;

const NAV_LINKS = [
  {
    path: "/",
    title: "Home",
  },
  {
    path: "/events",
    title: "Events",
  },
  {
    path: "/gallery",
    title: "Gallery",
  },
  {
    path: "/articles",
    title: "Articles",
  },
  {
    path: "/about-us",
    title: "About us",
  },
];

// import React, { useState } from "react";
// import { GiHamburgerMenu } from "react-icons/gi";
// import Button from "./Button";
// import "../css/navbar.css";

// import { routes } from "../api/routes";

// export default function Navbar() {
//   const [isVisble, setIsVisible] = useState(false);

//   //all menu items
//   const menuItems = [
//     {
//       title: "Home",
//       to: routes.base,
//     },
//     {
//       title: "Events",
//       to: routes.events,
//     },
//     {
//       title: "Gallery",
//       to: routes.gallery,
//     },
//     {
//       title: "Articles",
//       to: routes.articles,
//     },
//     {
//       title: "About Us",
//       to: routes.about,
//     },
//   ];

//   return (
//     <>
//       <div>
//         <div className="nav-container">
//           <div className="hamburger-menu">
//             <GiHamburgerMenu
//               size={30}
//               onClick={() => setIsVisible(!isVisble)}
//             />
//           </div>
//           <Button primary title="Join Now" />
//         </div>
//       </div>

//       {/* Show Menu Items in Mobile View */}
//       {isVisble && (
//         <div className="nav-modal">
//           <div className="hamburger-menu">
//             <GiHamburgerMenu
//               size={30}
//               onClick={() => setIsVisible(!isVisble)}
//               style={{ paddingBlock: 20, paddingInline: 10 }}
//             />
//           </div>
//           <div className="menu-items">
//             {/* Map each menu item with a button */}
//             {menuItems.map(({ title }) => (
//               <Button primary title={title} />
//             ))}
//           </div>
//         </div>
//       )}
//     </>
//   );
// }
