import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import { send } from "@emailjs/browser";

export default function Volunteers() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fileUrl =
    "https://docs.google.com/spreadsheets/d/1PxR0uxX1wP3wwrFFP8p3EweHfoGh08MgnZ_8k94AefM/edit?usp=sharing";
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the Excel file as an array buffer
        const response = await axios.get(fileUrl, {
          responseType: "arraybuffer",
        });

        // Convert the buffer into a readable workbook
        const workbook = XLSX.read(new Uint8Array(response.data), {
          type: "array",
        });

        // Assume the first sheet is the one you want to parse
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        // Convert sheet to JSON
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setData(formatTableData(jsonData));
      } catch (error) {
        console.error("Error fetching or parsing Excel file:", error);
      }
    };

    // Format the data into a structure that suits your table
    function formatTableData(dataArray) {
      const headers = [
        { title: "Full Name", index: "B" },
        { title: "Phone", index: "D" },
        { title: "Email", index: "E" },
        { title: "Hours", index: "K" },
        { title: "State", index: "L" },
        { title: "Info Email", index: "S" },
        { title: "Contract", index: "T" },
        { title: "Status", index: "U" },
      ];

      // Extract rows starting from index 1, assuming row 0 contains header info
      const rows = dataArray.slice(1).map((row) => {
        const rowData = {};
        headers.forEach(({ title, index }) => {
          // Handle Full Name by concatenating First and Last Name (assuming "C" contains Last Name)
          if (index === "B") {
            rowData[title] = (row[index] || "") + " " + (row["C"] || ""); // Make sure C exists
            return;
          }
          // Map each value to its corresponding header, use fallback for missing data
          rowData[title] = row[index] || ""; // Use empty string if no data
        });
        return rowData;
      });

      return rows; // Return formatted rows
    }

    fetchData();
  }, [fileUrl]);

  const handleSendEmail = async (email, name) => {
    setLoading(true);
    try {
      const { status, text } = await send(
        "service_1yfei36",
        "syticks_template",
        {
          to_name: name,
          to_email: email,
        }
      );
      console.log("email", text);
      if (text == "OK") {
        alert("Email sent");
        return;
      }
      alert(text);
    } catch (error) {
      alert("Failed to send Email");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="overflow-x-auto p-4">
      <h3 className="text-xl font-semibold mb-4">Volunteer Data</h3>
      <table className="table-auto w-full border-collapse border border-gray-200 shadow-md rounded-lg">
        <thead className="bg-gray-100">
          <tr>
            {data.length > 0 &&
              Object.keys(data[0]).map((key) => (
                <th
                  className="px-4 py-2 text-center border-b text-primary"
                  key={key}
                >
                  {key}
                </th>
              ))}
            <th
              className="px-4 py-2 text-center border-b text-primary"
              key="action"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => {
            if (typeof row.Phone != "number") {
              return;
            }
            return (
              <tr className="border-b " key={index}>
                {Object.values(row).map((cell, cellIndex) => (
                  <td className="px-4 py-2 text-center" key={cellIndex}>
                    {cell}
                  </td>
                ))}
                <td className="px-4 py-2 text-center">
                  <button
                    onClick={() => handleSendEmail(row.Email, row["Full Name"])}
                    disabled={row["Info Email"] == "Sent" || loading}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200 disabled:bg-gray-600"
                  >
                    Send Email
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
