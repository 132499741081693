export const routes = Object.freeze({
  base: "/",
  about: "/about-us",
  events: "events",
  joinUs: "/join-us",
  gallery: "/gallery",
  articles: "/articles",
  contactUs: "/contact-us",
  admin: {
    login: "/login",
    dashboard: "/dashboard",
    addEvent: "/addEvent",
    addTestimony: "/addTestimony",
    volunteers: "/volunteers",
  },
});
