import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppContext } from "../utils/providers/AppProvider";

export default function ProtectedRoute({ children }) {
  const location = useLocation();
  const { user, setUser } = useAppContext();
  const [loading, setLoading] = useState(true); // Track loading status

  useEffect(() => {
    // Retrieve user from session storage
    const storedUser = sessionStorage.getItem("user");
    console.log("storeduser", storedUser);
    if (storedUser) {
      setUser(JSON.parse(storedUser)); // Update state with parsed user data
    }
    setLoading(false); // Set loading to false once the user is fetched
  }, []);

  // Optional: Display a loading indicator while fetching user data
  if (loading) {
    return <div>Loading...</div>;
  }

  // // If no user or not an admin, redirect to the login page
  if (!user || !user.isAdmin) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // If user is valid and an admin, render protected children
  return children;
}
