import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { init } from "@emailjs/browser";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { routes } from "./api/routes";
import { Footer, Navbar } from "./components/";
import { AboutUs, OurGallery, Events, Articles, ArticleDetails } from "./pages";
import ProtectedRoute from "./routes/ProtectedRoute";
import { AppProvider } from "./utils/providers/AppProvider";
import { AddTestimony, AddEvent, Login, AdminDashboard } from "./pages/admin";
import Volunteers from "./pages/admin/Volunteers";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    init({ publicKey: "myhhPAHPDjzpHweZW" });
  }, []);

  return (
    <div className="text-white bg-primary font-poppins">
      <AppProvider>
        <BrowserRouter>
          <Navbar />
          <ToastContainer />

          <main style={{ minHeight: "calc(100vh - 90px)" }}>
            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <AdminDashboard />
                  </ProtectedRoute>
                }
              />
              <Route path={routes.about} element={<AboutUs />} />
              <Route path={routes.gallery} element={<OurGallery />} />
              <Route path={routes.articles} element={<Articles />} />
              <Route
                path={`${routes.articles}/:id`}
                element={<ArticleDetails />}
              />
              <Route path={routes.events} element={<Events />} />

              {/* admin rourtes */}
              <Route path={routes.admin.login} element={<Login />} />
              <Route
                path={routes.admin.dashboard}
                element={
                  <ProtectedRoute>
                    <AdminDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path={routes.admin.addEvent}
                element={
                  <ProtectedRoute>
                    <AddEvent />
                  </ProtectedRoute>
                }
              />
              <Route
                path={routes.admin.addTestimony}
                element={
                  <ProtectedRoute>
                    <AddTestimony />
                  </ProtectedRoute>
                }
              />
              <Route
                path={routes.admin.volunteers}
                element={
                  <ProtectedRoute>
                    <Volunteers />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </main>
          <Footer />
        </BrowserRouter>
      </AppProvider>
    </div>
  );
}

export default App;
